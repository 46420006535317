<template>
  <div class="balanceRecord" :style="isWeixin_status ? '': 'padding-top: 50px'">
    <NavBar v-if="!isWeixin_status" fixed title="我的奖品" @click-left="goBack" :left-arrow="isWeixin_status == false"></NavBar>
    <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="list-item" v-for="(item, idx) in listArr" :key="idx">
        <div>
          <span>奖项等级</span>
          <span>{{ item.product.level }}</span>
        </div>
        <div>
          <span>奖品</span>
          <span v-if="item.product.type == 0">积分{{item.product.amount}}</span>
          <span v-if="item.product.type == 1">余额{{item.product.amount}}元</span>
          <template v-if="item.product.type == 2">
            <span>优惠券</span>
            <!-- <span v-for="(cou, idx) in item.product.coupon" :key="idx">{{cou.title}}</span> -->
          </template>
          <span v-if="item.product.type == 3">物品{{item.product.item_name}}</span>
        </div>
        <div>
          <span>中奖时间</span>
          <span>{{ item.create_time }}</span>
        </div>
        <div v-if="item.product.type == 3">
          <span>实物核销</span>
          <div class="my-btn" @click="showMyCode(item.id)" v-if="item.deal_status == 0">去核销</div>
          <div class="my-btnPast" @click="showMyCode(item.id)"  v-if="item.deal_status == 1">已核销</div>
        </div>
      </div>
    </List>
    <van-dialog v-model="show" title="核销码">
      <img style="width:100%" :src="code_url" />
    </van-dialog>
  </div>
</template>
<script>
import { NavBar, List, Dialog } from "vant";
import Setting from "@/api/setting";
export default {
  components: {
    NavBar,
    List, 
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      loading: false,
      finished: false,
      listArr: [],
      show: false,
      details: {},
      page: 0,
      code_url: ""
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async onLoad() {
      this.page++;
      try {
        const res = await Setting.getMyward(this.page);
        if (res.code == 200) {
          if (res.data.total_num > 0) {
            res.data.result.forEach(item => {
              this.listArr.push(item);
            });
            this.loading = false;
            if (res.data.total_num == this.listArr.length) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 展示核销码
    async showMyCode(id) {
      try {
        const res = await Setting.showCode(id);
        this.code_url = res.data.url;
        this.show = true;
      } catch (error) {
        console.log(error)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.balanceRecord {
  width: 100%;
  height: 100vh;
  // padding-top: 50px;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  .list-item {
    width: 98%;
    min-height: 50px;
    margin: 10px auto;
    border-radius: 6px;
    padding: 10px 15px;
    background-color: #fff;
  }

  .list-item div {
    display: flex;
    height: 20px;
    justify-content: space-between;
  }
  .list-item div span:nth-child(1) {
    font-weight: 560;
    color: #363636;
  }
  .my-btn {
    color: #fff;
    height: 20px;
    border-radius: 20px;
    font-size: 12px;
    padding: 2px 6px;
    line-height: 20px;
    background: #99d8d0;
    text-align: center;
  }
  .my-btnPast {
    color: #fff;
    height: 20px;
    border-radius: 20px;
    font-size: 12px;
    padding: 2px 6px;
    line-height: 20px;
    background: #DD001b;
    text-align: center;
  }
}
</style>
